import { ZodError } from "zod"
import {
  type ISimplifiedError,
  type ISimplifiedErrorList
} from "../entities/simplifiedZodErrors"

export const simplifyZodErrors = (
  value: ZodError
) => {
  let errors: ISimplifiedErrorList = {}

  for (const item of value.errors) {
    const key = (item.path as string[]).join('.')
    errors[key] = item as ISimplifiedError
  }
  return errors
}

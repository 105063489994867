import { default as createaPXaYUcQI6Meta } from "/home/lemono/projects/lemono/apps/admin/pages/articles/create.vue?macro=true";
import { default as edit_45_91id_93ivC9mHRWXiMeta } from "/home/lemono/projects/lemono/apps/admin/pages/articles/edit-[id].vue?macro=true";
import { default as images_45_91id_93ulnFVhGyQmMeta } from "/home/lemono/projects/lemono/apps/admin/pages/articles/images-[id].vue?macro=true";
import { default as index7K8CT6hy1zMeta } from "/home/lemono/projects/lemono/apps/admin/pages/articles/index.vue?macro=true";
import { default as createy6gFXnYXXxMeta } from "/home/lemono/projects/lemono/apps/admin/pages/categories/create.vue?macro=true";
import { default as edit_45_91id_9352HzRbBCeuMeta } from "/home/lemono/projects/lemono/apps/admin/pages/categories/edit-[id].vue?macro=true";
import { default as images_45_91id_93ejRvWGo0aVMeta } from "/home/lemono/projects/lemono/apps/admin/pages/categories/images-[id].vue?macro=true";
import { default as index3guTZtkcVGMeta } from "/home/lemono/projects/lemono/apps/admin/pages/categories/index.vue?macro=true";
import { default as specs_45_91id_93jnTnRCRjuHMeta } from "/home/lemono/projects/lemono/apps/admin/pages/categories/specs-[id].vue?macro=true";
import { default as createiyEUWQGzkkMeta } from "/home/lemono/projects/lemono/apps/admin/pages/comments/create.vue?macro=true";
import { default as edit_45_91id_93iqnS8dFjciMeta } from "/home/lemono/projects/lemono/apps/admin/pages/comments/edit-[id].vue?macro=true";
import { default as indexkj24uJrfQfMeta } from "/home/lemono/projects/lemono/apps/admin/pages/comments/index.vue?macro=true";
import { default as createAS3Zm8qsf3Meta } from "/home/lemono/projects/lemono/apps/admin/pages/contacts/create.vue?macro=true";
import { default as edit_45_91id_936BhcXpzJ8eMeta } from "/home/lemono/projects/lemono/apps/admin/pages/contacts/edit-[id].vue?macro=true";
import { default as indexnbmMzYGudtMeta } from "/home/lemono/projects/lemono/apps/admin/pages/contacts/index.vue?macro=true";
import { default as indexPOqk7A4zqZMeta } from "/home/lemono/projects/lemono/apps/admin/pages/discounts/index.vue?macro=true";
import { default as createN4i83U6CJrMeta } from "/home/lemono/projects/lemono/apps/admin/pages/guarantees/create.vue?macro=true";
import { default as edit_45_91id_93Pp8gFzaclfMeta } from "/home/lemono/projects/lemono/apps/admin/pages/guarantees/edit-[id].vue?macro=true";
import { default as indexBtUkHAk3jXMeta } from "/home/lemono/projects/lemono/apps/admin/pages/guarantees/index.vue?macro=true";
import { default as indexl2wOkIy05HMeta } from "/home/lemono/projects/lemono/apps/admin/pages/index.vue?macro=true";
import { default as _91_91level_93_93XFXi0MLJY7Meta } from "/home/lemono/projects/lemono/apps/admin/pages/login/[[level]].vue?macro=true";
import { default as edit_45_91id_93ciFzehAyHnMeta } from "/home/lemono/projects/lemono/apps/admin/pages/orders/edit-[id].vue?macro=true";
import { default as index8olnRwNIKdMeta } from "/home/lemono/projects/lemono/apps/admin/pages/orders/index.vue?macro=true";
import { default as create2EJ8bhhTB5Meta } from "/home/lemono/projects/lemono/apps/admin/pages/products/create.vue?macro=true";
import { default as edit_45_91id_93E035eMtgMhMeta } from "/home/lemono/projects/lemono/apps/admin/pages/products/edit-[id].vue?macro=true";
import { default as images_45_91id_93LkCiVamghgMeta } from "/home/lemono/projects/lemono/apps/admin/pages/products/images-[id].vue?macro=true";
import { default as index0iXPPBdtOhMeta } from "/home/lemono/projects/lemono/apps/admin/pages/products/index.vue?macro=true";
import { default as specs_45_91id_93D7fMEtXHBuMeta } from "/home/lemono/projects/lemono/apps/admin/pages/products/specs-[id].vue?macro=true";
import { default as createJkNuf2ewxmMeta } from "/home/lemono/projects/lemono/apps/admin/pages/qa/create.vue?macro=true";
import { default as edit_45_91id_930spwZOLGhBMeta } from "/home/lemono/projects/lemono/apps/admin/pages/qa/edit-[id].vue?macro=true";
import { default as indexrQvyi5ZCLpMeta } from "/home/lemono/projects/lemono/apps/admin/pages/qa/index.vue?macro=true";
import { default as createytDdKIKr0mMeta } from "/home/lemono/projects/lemono/apps/admin/pages/service-requests/create.vue?macro=true";
import { default as edit_45_91id_93T1UFjs0hepMeta } from "/home/lemono/projects/lemono/apps/admin/pages/service-requests/edit-[id].vue?macro=true";
import { default as index54dzJip1Q7Meta } from "/home/lemono/projects/lemono/apps/admin/pages/service-requests/index.vue?macro=true";
import { default as settingsgI86nErsZfMeta } from "/home/lemono/projects/lemono/apps/admin/pages/settings.vue?macro=true";
import { default as index2qvADi7NV0Meta } from "/home/lemono/projects/lemono/apps/admin/pages/transactions/index.vue?macro=true";
import { default as view_45_91id_93HJnEvHNCHtMeta } from "/home/lemono/projects/lemono/apps/admin/pages/transactions/view-[id].vue?macro=true";
import { default as createD3HUO0zzz0Meta } from "/home/lemono/projects/lemono/apps/admin/pages/users/create.vue?macro=true";
import { default as edit_45_91id_93oF1fwI1rOOMeta } from "/home/lemono/projects/lemono/apps/admin/pages/users/edit-[id].vue?macro=true";
import { default as index8AZqUvIwrmMeta } from "/home/lemono/projects/lemono/apps/admin/pages/users/index.vue?macro=true";
export default [
  {
    name: createaPXaYUcQI6Meta?.name ?? "articles-create",
    path: createaPXaYUcQI6Meta?.path ?? "/articles/create",
    meta: createaPXaYUcQI6Meta || {},
    alias: createaPXaYUcQI6Meta?.alias || [],
    redirect: createaPXaYUcQI6Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/articles/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_93ivC9mHRWXiMeta?.name ?? "articles-edit-id",
    path: edit_45_91id_93ivC9mHRWXiMeta?.path ?? "/articles/edit-:id()",
    meta: edit_45_91id_93ivC9mHRWXiMeta || {},
    alias: edit_45_91id_93ivC9mHRWXiMeta?.alias || [],
    redirect: edit_45_91id_93ivC9mHRWXiMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/articles/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: images_45_91id_93ulnFVhGyQmMeta?.name ?? "articles-images-id",
    path: images_45_91id_93ulnFVhGyQmMeta?.path ?? "/articles/images-:id()",
    meta: images_45_91id_93ulnFVhGyQmMeta || {},
    alias: images_45_91id_93ulnFVhGyQmMeta?.alias || [],
    redirect: images_45_91id_93ulnFVhGyQmMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/articles/images-[id].vue").then(m => m.default || m)
  },
  {
    name: index7K8CT6hy1zMeta?.name ?? "articles",
    path: index7K8CT6hy1zMeta?.path ?? "/articles",
    meta: index7K8CT6hy1zMeta || {},
    alias: index7K8CT6hy1zMeta?.alias || [],
    redirect: index7K8CT6hy1zMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: createy6gFXnYXXxMeta?.name ?? "categories-create",
    path: createy6gFXnYXXxMeta?.path ?? "/categories/create",
    meta: createy6gFXnYXXxMeta || {},
    alias: createy6gFXnYXXxMeta?.alias || [],
    redirect: createy6gFXnYXXxMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/categories/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_9352HzRbBCeuMeta?.name ?? "categories-edit-id",
    path: edit_45_91id_9352HzRbBCeuMeta?.path ?? "/categories/edit-:id()",
    meta: edit_45_91id_9352HzRbBCeuMeta || {},
    alias: edit_45_91id_9352HzRbBCeuMeta?.alias || [],
    redirect: edit_45_91id_9352HzRbBCeuMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/categories/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: images_45_91id_93ejRvWGo0aVMeta?.name ?? "categories-images-id",
    path: images_45_91id_93ejRvWGo0aVMeta?.path ?? "/categories/images-:id()",
    meta: images_45_91id_93ejRvWGo0aVMeta || {},
    alias: images_45_91id_93ejRvWGo0aVMeta?.alias || [],
    redirect: images_45_91id_93ejRvWGo0aVMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/categories/images-[id].vue").then(m => m.default || m)
  },
  {
    name: index3guTZtkcVGMeta?.name ?? "categories",
    path: index3guTZtkcVGMeta?.path ?? "/categories",
    meta: index3guTZtkcVGMeta || {},
    alias: index3guTZtkcVGMeta?.alias || [],
    redirect: index3guTZtkcVGMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: specs_45_91id_93jnTnRCRjuHMeta?.name ?? "categories-specs-id",
    path: specs_45_91id_93jnTnRCRjuHMeta?.path ?? "/categories/specs-:id()",
    meta: specs_45_91id_93jnTnRCRjuHMeta || {},
    alias: specs_45_91id_93jnTnRCRjuHMeta?.alias || [],
    redirect: specs_45_91id_93jnTnRCRjuHMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/categories/specs-[id].vue").then(m => m.default || m)
  },
  {
    name: createiyEUWQGzkkMeta?.name ?? "comments-create",
    path: createiyEUWQGzkkMeta?.path ?? "/comments/create",
    meta: createiyEUWQGzkkMeta || {},
    alias: createiyEUWQGzkkMeta?.alias || [],
    redirect: createiyEUWQGzkkMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/comments/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_93iqnS8dFjciMeta?.name ?? "comments-edit-id",
    path: edit_45_91id_93iqnS8dFjciMeta?.path ?? "/comments/edit-:id()",
    meta: edit_45_91id_93iqnS8dFjciMeta || {},
    alias: edit_45_91id_93iqnS8dFjciMeta?.alias || [],
    redirect: edit_45_91id_93iqnS8dFjciMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/comments/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: indexkj24uJrfQfMeta?.name ?? "comments",
    path: indexkj24uJrfQfMeta?.path ?? "/comments",
    meta: indexkj24uJrfQfMeta || {},
    alias: indexkj24uJrfQfMeta?.alias || [],
    redirect: indexkj24uJrfQfMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/comments/index.vue").then(m => m.default || m)
  },
  {
    name: createAS3Zm8qsf3Meta?.name ?? "contacts-create",
    path: createAS3Zm8qsf3Meta?.path ?? "/contacts/create",
    meta: createAS3Zm8qsf3Meta || {},
    alias: createAS3Zm8qsf3Meta?.alias || [],
    redirect: createAS3Zm8qsf3Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/contacts/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_936BhcXpzJ8eMeta?.name ?? "contacts-edit-id",
    path: edit_45_91id_936BhcXpzJ8eMeta?.path ?? "/contacts/edit-:id()",
    meta: edit_45_91id_936BhcXpzJ8eMeta || {},
    alias: edit_45_91id_936BhcXpzJ8eMeta?.alias || [],
    redirect: edit_45_91id_936BhcXpzJ8eMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/contacts/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: indexnbmMzYGudtMeta?.name ?? "contacts",
    path: indexnbmMzYGudtMeta?.path ?? "/contacts",
    meta: indexnbmMzYGudtMeta || {},
    alias: indexnbmMzYGudtMeta?.alias || [],
    redirect: indexnbmMzYGudtMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexPOqk7A4zqZMeta?.name ?? "discounts",
    path: indexPOqk7A4zqZMeta?.path ?? "/discounts",
    meta: indexPOqk7A4zqZMeta || {},
    alias: indexPOqk7A4zqZMeta?.alias || [],
    redirect: indexPOqk7A4zqZMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: createN4i83U6CJrMeta?.name ?? "guarantees-create",
    path: createN4i83U6CJrMeta?.path ?? "/guarantees/create",
    meta: createN4i83U6CJrMeta || {},
    alias: createN4i83U6CJrMeta?.alias || [],
    redirect: createN4i83U6CJrMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/guarantees/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_93Pp8gFzaclfMeta?.name ?? "guarantees-edit-id",
    path: edit_45_91id_93Pp8gFzaclfMeta?.path ?? "/guarantees/edit-:id()",
    meta: edit_45_91id_93Pp8gFzaclfMeta || {},
    alias: edit_45_91id_93Pp8gFzaclfMeta?.alias || [],
    redirect: edit_45_91id_93Pp8gFzaclfMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/guarantees/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: indexBtUkHAk3jXMeta?.name ?? "guarantees",
    path: indexBtUkHAk3jXMeta?.path ?? "/guarantees",
    meta: indexBtUkHAk3jXMeta || {},
    alias: indexBtUkHAk3jXMeta?.alias || [],
    redirect: indexBtUkHAk3jXMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/guarantees/index.vue").then(m => m.default || m)
  },
  {
    name: indexl2wOkIy05HMeta?.name ?? "index",
    path: indexl2wOkIy05HMeta?.path ?? "/",
    meta: indexl2wOkIy05HMeta || {},
    alias: indexl2wOkIy05HMeta?.alias || [],
    redirect: indexl2wOkIy05HMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91level_93_93XFXi0MLJY7Meta?.name ?? "login-level",
    path: _91_91level_93_93XFXi0MLJY7Meta?.path ?? "/login/:level?",
    meta: _91_91level_93_93XFXi0MLJY7Meta || {},
    alias: _91_91level_93_93XFXi0MLJY7Meta?.alias || [],
    redirect: _91_91level_93_93XFXi0MLJY7Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/login/[[level]].vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_93ciFzehAyHnMeta?.name ?? "orders-edit-id",
    path: edit_45_91id_93ciFzehAyHnMeta?.path ?? "/orders/edit-:id()",
    meta: edit_45_91id_93ciFzehAyHnMeta || {},
    alias: edit_45_91id_93ciFzehAyHnMeta?.alias || [],
    redirect: edit_45_91id_93ciFzehAyHnMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/orders/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: index8olnRwNIKdMeta?.name ?? "orders",
    path: index8olnRwNIKdMeta?.path ?? "/orders",
    meta: index8olnRwNIKdMeta || {},
    alias: index8olnRwNIKdMeta?.alias || [],
    redirect: index8olnRwNIKdMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: create2EJ8bhhTB5Meta?.name ?? "products-create",
    path: create2EJ8bhhTB5Meta?.path ?? "/products/create",
    meta: create2EJ8bhhTB5Meta || {},
    alias: create2EJ8bhhTB5Meta?.alias || [],
    redirect: create2EJ8bhhTB5Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_93E035eMtgMhMeta?.name ?? "products-edit-id",
    path: edit_45_91id_93E035eMtgMhMeta?.path ?? "/products/edit-:id()",
    meta: edit_45_91id_93E035eMtgMhMeta || {},
    alias: edit_45_91id_93E035eMtgMhMeta?.alias || [],
    redirect: edit_45_91id_93E035eMtgMhMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/products/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: images_45_91id_93LkCiVamghgMeta?.name ?? "products-images-id",
    path: images_45_91id_93LkCiVamghgMeta?.path ?? "/products/images-:id()",
    meta: images_45_91id_93LkCiVamghgMeta || {},
    alias: images_45_91id_93LkCiVamghgMeta?.alias || [],
    redirect: images_45_91id_93LkCiVamghgMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/products/images-[id].vue").then(m => m.default || m)
  },
  {
    name: index0iXPPBdtOhMeta?.name ?? "products",
    path: index0iXPPBdtOhMeta?.path ?? "/products",
    meta: index0iXPPBdtOhMeta || {},
    alias: index0iXPPBdtOhMeta?.alias || [],
    redirect: index0iXPPBdtOhMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: specs_45_91id_93D7fMEtXHBuMeta?.name ?? "products-specs-id",
    path: specs_45_91id_93D7fMEtXHBuMeta?.path ?? "/products/specs-:id()",
    meta: specs_45_91id_93D7fMEtXHBuMeta || {},
    alias: specs_45_91id_93D7fMEtXHBuMeta?.alias || [],
    redirect: specs_45_91id_93D7fMEtXHBuMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/products/specs-[id].vue").then(m => m.default || m)
  },
  {
    name: createJkNuf2ewxmMeta?.name ?? "qa-create",
    path: createJkNuf2ewxmMeta?.path ?? "/qa/create",
    meta: createJkNuf2ewxmMeta || {},
    alias: createJkNuf2ewxmMeta?.alias || [],
    redirect: createJkNuf2ewxmMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/qa/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_930spwZOLGhBMeta?.name ?? "qa-edit-id",
    path: edit_45_91id_930spwZOLGhBMeta?.path ?? "/qa/edit-:id()",
    meta: edit_45_91id_930spwZOLGhBMeta || {},
    alias: edit_45_91id_930spwZOLGhBMeta?.alias || [],
    redirect: edit_45_91id_930spwZOLGhBMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/qa/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: indexrQvyi5ZCLpMeta?.name ?? "qa",
    path: indexrQvyi5ZCLpMeta?.path ?? "/qa",
    meta: indexrQvyi5ZCLpMeta || {},
    alias: indexrQvyi5ZCLpMeta?.alias || [],
    redirect: indexrQvyi5ZCLpMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/qa/index.vue").then(m => m.default || m)
  },
  {
    name: createytDdKIKr0mMeta?.name ?? "service-requests-create",
    path: createytDdKIKr0mMeta?.path ?? "/service-requests/create",
    meta: createytDdKIKr0mMeta || {},
    alias: createytDdKIKr0mMeta?.alias || [],
    redirect: createytDdKIKr0mMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/service-requests/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_93T1UFjs0hepMeta?.name ?? "service-requests-edit-id",
    path: edit_45_91id_93T1UFjs0hepMeta?.path ?? "/service-requests/edit-:id()",
    meta: edit_45_91id_93T1UFjs0hepMeta || {},
    alias: edit_45_91id_93T1UFjs0hepMeta?.alias || [],
    redirect: edit_45_91id_93T1UFjs0hepMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/service-requests/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: index54dzJip1Q7Meta?.name ?? "service-requests",
    path: index54dzJip1Q7Meta?.path ?? "/service-requests",
    meta: index54dzJip1Q7Meta || {},
    alias: index54dzJip1Q7Meta?.alias || [],
    redirect: index54dzJip1Q7Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/service-requests/index.vue").then(m => m.default || m)
  },
  {
    name: settingsgI86nErsZfMeta?.name ?? "settings",
    path: settingsgI86nErsZfMeta?.path ?? "/settings",
    meta: settingsgI86nErsZfMeta || {},
    alias: settingsgI86nErsZfMeta?.alias || [],
    redirect: settingsgI86nErsZfMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: index2qvADi7NV0Meta?.name ?? "transactions",
    path: index2qvADi7NV0Meta?.path ?? "/transactions",
    meta: index2qvADi7NV0Meta || {},
    alias: index2qvADi7NV0Meta?.alias || [],
    redirect: index2qvADi7NV0Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: view_45_91id_93HJnEvHNCHtMeta?.name ?? "transactions-view-id",
    path: view_45_91id_93HJnEvHNCHtMeta?.path ?? "/transactions/view-:id()",
    meta: view_45_91id_93HJnEvHNCHtMeta || {},
    alias: view_45_91id_93HJnEvHNCHtMeta?.alias || [],
    redirect: view_45_91id_93HJnEvHNCHtMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/transactions/view-[id].vue").then(m => m.default || m)
  },
  {
    name: createD3HUO0zzz0Meta?.name ?? "users-create",
    path: createD3HUO0zzz0Meta?.path ?? "/users/create",
    meta: createD3HUO0zzz0Meta || {},
    alias: createD3HUO0zzz0Meta?.alias || [],
    redirect: createD3HUO0zzz0Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: edit_45_91id_93oF1fwI1rOOMeta?.name ?? "users-edit-id",
    path: edit_45_91id_93oF1fwI1rOOMeta?.path ?? "/users/edit-:id()",
    meta: edit_45_91id_93oF1fwI1rOOMeta || {},
    alias: edit_45_91id_93oF1fwI1rOOMeta?.alias || [],
    redirect: edit_45_91id_93oF1fwI1rOOMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/users/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: index8AZqUvIwrmMeta?.name ?? "users",
    path: index8AZqUvIwrmMeta?.path ?? "/users",
    meta: index8AZqUvIwrmMeta || {},
    alias: index8AZqUvIwrmMeta?.alias || [],
    redirect: index8AZqUvIwrmMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/admin/pages/users/index.vue").then(m => m.default || m)
  }
]
<script setup lang="ts">
import {
  ArchiveBoxIcon,
  ArrowPathRoundedSquareIcon,
  ArrowRightStartOnRectangleIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  TagIcon,
  UsersIcon,
  WrenchScrewdriverIcon
} from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
const { fullname, user } = storeToRefs(authStore)
const logout = () => authStore.logout()
</script>

<template lang="pug">
.admin-sidebar(class="w-[15rem] h-screen flex flex-col fixed top-0")
  .top-part(class="p-8")
    div(class="flex gap-4 items-center")
      div(class="size-14")
        Avatar
      div(class="flex flex-col")
        div(class="font-semibold") {{ fullname || '' }}
        div(class="text-sm text-gray-600") {{ user?.phone || '' }}
  .menu(class="flex flex-col grow")
    AdminSidebarMenuItem(to="/" :icon="PresentationChartLineIcon") داشبورد
    AdminSidebarMenuItem(to="/users" :icon="UsersIcon") کاربران
    AdminSidebarMenuItem(to="/categories" :icon="TagIcon") دسته‌بندی‌ها
    AdminSidebarMenuItem(to="/products" :icon="ArchiveBoxIcon") محصولات
    AdminSidebarMenuItem(to="/comments" :icon="ChatBubbleLeftRightIcon") دیدگاه‌ها
    AdminSidebarMenuItem(to="/qa" :icon="QuestionMarkCircleIcon") سوال و جواب
    AdminSidebarMenuItem(to="/orders" :icon="ShoppingBagIcon") سفارش‌ها
    AdminSidebarMenuItem(to="/transactions" :icon="CurrencyDollarIcon") تراکنش‌ها
    AdminSidebarMenuItem(to="/service-requests" :icon="WrenchScrewdriverIcon") درخواست‌ها
    AdminSidebarMenuItem(to="/guarantees" :icon="CheckBadgeIcon") گارانتی
    AdminSidebarMenuItem(to="/articles" :icon="NewspaperIcon") مقالات
    AdminSidebarMenuItem(to="/contacts" :icon="ArrowPathRoundedSquareIcon") ارتباط با ما
    AdminSidebarMenuItem(to="/settings" :icon="Cog6ToothIcon") تنظیمات
    .my-auto
    AdminSidebarMenuItem(@click="logout" :icon="ArrowRightStartOnRectangleIcon") خروج

    div(class="h-5")
</template>

<script setup lang="ts">
import {
  ArrowRightIcon,
  EnvelopeIcon
} from '@heroicons/vue/24/outline'

const firstname = ref('')
const lastname = ref('')
const email = ref()

const loginStore = useLoginStore()
const onSubmit = async () => {
  const result = await loginStore.submitNameEmail(
    firstname.value,
    lastname.value,
    email.value
  )

  result && navigateTo('')
}
</script>

<template lang="pug">
.login-code-form
  .desc(class="leading-loose")
    p نام و نام خانوادگی خود را وارد کنید
    p ورود ایمیل اختیاری است
  
  form(@submit.prevent="onSubmit" class="mt-20")
    .field
      div(class="grid gap-4 grid-cols-2")
        .input
          input(
            v-model="firstname"
            placeholder="نام"
          )
        .input
          input(
            v-model="lastname"
            placeholder="نام خانوادگی"
          )
        .input(class="col-span-2")
          input.email(
            v-model="email"
            placeholder="name@example.ext"
          )
          EnvelopeIcon(class="h-8 text-gray-500")

    .buttons(class="flex items-center justify-between h-3")
      button.button(type="submit" class="flex items-center justify-center gap-4 ext-center bg-blue-500 w-48 hover:bg-blue-700")
        ArrowRightIcon(class="h-6")
        span تکمیل ثبت‌نام
</template>

<style lang="stylus" scoped>
.login-code-form
  .field
    .input
      @apply flex items-center bg-white rounded-full text-black px-5
      input
        @apply bg-transparent border-0 flex-grow shadow-none
        &.email
          direction ltr
          text-align left
</style>

<script setup lang="ts">
import { useAuthStore } from '../../api-layer/stores/authStore'

if (!useAuthStore().isAdmin)
  navigateTo('/login')
</script>

<template lang="pug">
.admin-layout(class="grid w-full min-h-screen bg-gray-200 grid-cols-[15rem_minmax(0,_1fr)]")
  .sidebar(class="bg-white shadow")
    AdminSidebar
  .content(class="p-6")
    slot

ToastContainer(position="bottom-center")
</template>

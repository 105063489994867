import revive_payload_client_YTGlmQWWT5 from "/home/lemono/projects/lemono/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/lemono/projects/lemono/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/lemono/projects/lemono/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/lemono/projects/lemono/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/lemono/projects/lemono/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/home/lemono/projects/lemono/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/lemono/projects/lemono/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/lemono/projects/lemono/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_r3pgF3B1Uq from "/home/lemono/projects/lemono/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_iusAUtqzOR from "/home/lemono/projects/lemono/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import leaflet_runtime_ZOlCTIuil5 from "/home/lemono/projects/lemono/node_modules/nuxt3-leaflet/dist/runtime/leaflet-runtime.mjs";
import i18n_dOTnCc6TUQ from "/home/lemono/projects/lemono/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/home/lemono/projects/lemono/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_client_r3pgF3B1Uq,
  plugin_iusAUtqzOR,
  leaflet_runtime_ZOlCTIuil5,
  i18n_dOTnCc6TUQ,
  chunk_reload_client_SeG0EjL5Ec
]
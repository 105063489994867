<script setup lang="ts">
const emit = defineEmits(['remainingTime'])

const props = defineProps({
  expireTime: { type: Number, required: true }
})

const interval = ref()
const clearTimer = () => clearInterval(interval.value)

const remaningTime = ref(getConfig().loginCodeExpireDuration)
const calcRemainingTime = () => {
  const interval = props.expireTime - Math.floor(Date.now() / 1000)
  remaningTime.value = Math.floor(interval)
  emit('remainingTime', remaningTime.value)
}

const countDown = computed(() => {
  const min = `${(Math.floor(remaningTime.value / 60)).toString().padStart(2, '0')}`
  const sec = `${(remaningTime.value % 60).toString().padStart(2, '0')} `
  return `${min} : ${sec} `
})

watch(() => props.expireTime, value => {
  clearTimer()
  interval.value = setInterval(() => calcRemainingTime(), 1000)
}, { immediate: true })

onBeforeUnmount(() => clearTimer())
</script>

<template lang="pug">
div(class="dir-ltr") {{ countDown }}
</template>

<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
} from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { persianToEnglishDigits } from 'shared'
import { useLoginStore } from '~/stores/loginStore'

const code = ref('')

const loginStore = useLoginStore()
const { loginByPhoneResponse } = storeToRefs(loginStore)

const showError = ref(false)


const remaningTime = ref(getConfig().loginCodeExpireDuration)
const setRemainingTime = (value: number) => remaningTime.value = value


const expireTime = ref<number>(0)
const setExpireTime = () =>
  expireTime.value = (Date.now() / 1000) + (loginByPhoneResponse.value?.codeExpireInterval || 0)
setExpireTime()


const shouldResendBeActive = computed(() => remaningTime.value <= 0)
const submitCodeLoading = ref(false)

const submitCode = async () => {
  showError.value = false
  submitCodeLoading.value = true

  code.value = persianToEnglishDigits(code.value)
  const result = await loginStore.submitCode(code.value)
  if (!result)
    showError.value = true

  submitCodeLoading.value = false
}


const resendLoading = ref(false)

const resendCode = async () => {
  resendLoading.value = true
  await loginStore.resendCode()
  setRemainingTime(getConfig().loginCodeExpireDuration)
  setExpireTime()
  resendLoading.value = false
}

const editPhone = () => navigateTo('/login')
</script>

<template lang="pug">
.login-code-form
  .desc(class="leading-loose")
    p شماره همراه:
      div(class="inline text-lg px-5") {{ loginStore.loginPhone }}
    p کد تایید به شماره همراه شما ارسال شد.
    p در صورت عدم دریافت کد بعد از اتمام زمان، کلید "ارسال مجدد کد" را کلیک کنید

  form(@submit.prevent="submitCode" class="mt-20")
    .field
      div(class="pr-2 mb-2") لطفا کد تایید دریافتی را وارد کنید
      .input(class="flex items-center bg-white rounded-full text-black px-5")
        input(
          v-model="code"
          placeholder="****"
          type="number"
          class="bg-transparent flex-grow text-center tracking-[.2rem] text-[1.5rem] w-full dir-ltr"
          style="border: 0; box-shadow: none"
        )
        ShieldExclamationIcon(class="h-8 text-teal-600")
    template(v-if="showError == true")
      .error-message(class="mt-3 flex items-center gap-2 mt-2 text-orange-300")
        ExclamationTriangleIcon(class="h-6")
        span کد وارد شده صحیح نمی‌باشد

    .buttons(class="flex items-center justify-between mt-3")
      template(v-if="shouldResendBeActive")
        Button(tag="button" @buttonClicked="resendCode" view="blue" :loading="resendLoading")
          span ارسال مجدد کد
          template(v-slot:icon-prefix)
            template(v-if="resendLoading == false")
              ArrowRightIcon(class="h-5")

        Button(tag="button" @buttonClicked="editPhone" view="blue")
          span اصلاح شماره همراه
          template(v-slot:icon-suffix)
            ArrowLeftIcon(class="h-5")

      template(v-else)
        Button(
          tag="submit"
          view="blue" 
          :loading="submitCodeLoading"
          class="flex items-center justify-between text-orange-300"
          @buttonClicked="submitCode"
        )
          span ارسال کد ورود
          template(v-slot:icon-prefix)
            template(v-if="submitCodeLoading == false")
              ArrowRightIcon(class="h-5")

        .countdown(class="font-semibold text-xl text-left")
          AuthCountdown(
            :expireTime="expireTime"
            @remainingTime="setRemainingTime"
          )
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

if (useAuthStore().isLoggedIn && useAuthStore().isAdmin)
  navigateTo('/')

const loginStore = useLoginStore()
const { level } = storeToRefs(loginStore)

useHead({
  title: 'ورود | صفحه اصلی',
  link: [{
    rel: 'canonical',
    href: getConfig().baseUrl +  useRoute().path,
  }],
})
</script>

<template lang="pug">
.login-page
  NuxtLayout(name="auth")
    .container.mx-auto.py-20
      .login-page-grid.rounded-xl.overflow-hidden
        .image
          img(src="/img/login-page.webp")
        .data
          .parts
            .title.text-2xl به لمونو خوش آمدید
            template(v-if="level == 'phone'")
              AuthPhone
            template(v-else-if="level == 'code'")
              AuthCode
            template(v-else-if="level == 'details'")
              AuthDetails
</template>

<style lang="stylus" scoped>
.login-page
  .container
    @apply flex justify-center

    .login-page-grid
      @apply grid
      max-width 35rem

      img
        @apply w-full h-full object-cover hidden
      .data
        @apply grid items-center justify-center text-white px-10 py-12
        background-color #2e2e2e
        .parts
          @apply grid gap-6

@screen lg
  .login-page
    .container
      .login-page-grid
        @apply grid-cols-2 max-w-none
        img
          @apply block
        .data
          @apply px-20 py-20
          .parts
            .field
              input
                width 30rem
</style>

<script setup lang="ts">
import {
  ArrowRightIcon,
  DevicePhoneMobileIcon,
  ExclamationTriangleIcon
} from '@heroicons/vue/24/outline'
import { persianToEnglishDigits } from 'shared'
import { useLoginStore } from '~/stores/loginStore'

const emit = defineEmits(['phoneSubmit'])
const loginStore = useLoginStore()

const phone = ref('')
const showError = ref(false)

const buttonDisabled = computed(() => phone.value.length < 11)
const loading = ref(false)

const onSubmit = async () => {
  if (loading.value)
    return

  showError.value = false
  loading.value = true

  if (phone.value.length >= 11) {
    phone.value = persianToEnglishDigits(phone.value)
    const result = await loginStore.submitPhone(phone.value)
    if (!result)
      showError.value = true
  } else
    showError.value = true

  loading.value = false
}
</script>

<template lang="pug">
.login-phone-form
  .desc(class="leading-loose")
    p شماره موبایل خود را وارد کنید. کد ورود برای شما ارسال خواهد شد.
    p پس از ورود میتوانید اطلاعات خود را کامل کنید.

  form(@submit.prevent="onSubmit" class="mt-20")
    .field
      div(class="pr-2 mb-2") لطفا شماره موبایل خود را وارد کنید
      .input(class="flex items-center bg-white rounded-full text-black px-5")
        input(
          v-model="phone"
          placeholder="0912*******"
          autofocus
          class="bg-transparent border-0 flex-grow shadow-none tracking-[.2rem] text-[1.5rem] w-full dir-ltr"
          style="border: 0; box-shadow: none"
        )
        DevicePhoneMobileIcon.h-7.text-teal-600
    template(v-if="showError == true")
      .error-message(class="flex items-center gap-2 mt-2 text-orange-300")
        ExclamationTriangleIcon.h-6
        span شماره وارد شده صحیح نمی‌باشد

    .buttons(class="flex justify-start mt-5")
      Button(
        type="submit"
        :disabled="buttonDisabled"
        view="blue"
        :loading="loading"
      )
        span ارسال کد ورود
        template(v-slot:icon-prefix)
          template(v-if="loading == false")
            ArrowRightIcon(class="h-5")
</template>

<script setup lang="ts">
await useBootstrapStore().init(true, false)

useHead({
  titleTemplate: 'مدیریت | %s',
  htmlAttrs: {
    lang: 'fa'
  },
  meta: [
    { name: 'description', content: getMainDescription() }
  ]
})
</script>

<template lang="pug">
NuxtPage
</template>

import { defineStore } from "pinia"
import type { IAuthUserLogin, ILoginByPhoneCodeResponse, ILoginByPhoneResponse } from "shared"
import { useAuthStore } from "./authStore"


export const useLoginStore = defineStore('loginStore', () => {
  const authStore = useAuthStore()

  const level = ref<'phone' | 'code' | 'details'>('phone')

  const resetLevel = () => level.value = 'phone'

  const loginPhone = ref<string | undefined>()
  const loginByPhoneResponse = ref<ILoginByPhoneResponse>()

  const submitPhone = async (
    phone: string
  ) => {
    const url = 'auth/login/phone'
    const { data, error } =
      await useApi<ILoginByPhoneResponse>(url, {
        method: 'post',
        body: { phone },
      })

    if (error.value)
      return false

    loginPhone.value = phone

    if (data.value && data.value.codeSent) {
      loginByPhoneResponse.value = data.value
      level.value = 'code'
      return true
    }
    else
      return false
  }

  const submitCode = async (
    code: string
  ) => {
    const url = 'auth/login/phone/code'
    const { data, error } =
      await useApi<ILoginByPhoneCodeResponse>(url, {
        method: 'post',
        body: { code },
        cache: 'no-cache'
      })

    if (
      error.value == undefined &&
      data.value.valid &&
      data.value.user != undefined &&
      data.value.token != undefined
    ) {
      authStore.setToken(data.value.token)
      authStore.setUser(data.value.user)

      if (data.value.nameEntered) {
        navigateTo('/')
        resetLevel()
      } else
        level.value = 'details'

      return true
    } else
      return false
  }

  const resendCode = async () => {
    if (loginPhone.value)
      return await submitPhone(loginPhone.value)
    else
      return false
  }

  const submitNameEmail = async (
    firstname: string,
    lastname: string,
    email: string
  ) => {
    const url = 'auth/login/phone/name-email'
    const { data, error } = await useApi<IAuthUserLogin>(url, {
      method: 'post',
      body: { firstname, lastname, email }
    })

    if (error.value == undefined) {
      authStore.setUser(data.value)
      return true
    } else {
      console.log(error.value)
      return false
    }
  }

  return {
    level,
    loginByPhoneResponse,
    loginPhone,
    submitPhone,
    submitCode,
    submitNameEmail,
    resendCode,
  }
})
